import React from 'react';
import './footer.css'

function footer() {

    
    return (
        <div id="footerbar">
            <div id="links"> 
                <p>2020 c-r-sullivan</p>
            </div>
        </div>
    );
    
  }
  
  export default footer;