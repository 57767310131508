import React from 'react';
import './nav.css'

function nav() {

    
    return (
        <div id="navbar">
            <div className="links"> 
                <a href="https://c-r-sullivan.com/about">About</a>
                <a href="https://c-r-sullivan.com/">Home</a>
            </div>
        </div>
    );
    
  }
  
  export default nav;